.wrap {
  background-color: #f7f7f7;
  height: 100%;
  position: relative;
  padding: 40px;

  @media (max-width: 768px) {
    padding: 40px 10px;
  }

  > form {
    margin: 0 auto;
    max-width: 726px;
  }
}

.btnClose {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  svg {
    max-width: 22px;
  }
}

.countDown {
  width: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  .time {
    background-color: #ffffff;
    padding: 6px 10px;
    border-radius: 16px;
    font-size: 18px;
    font-weight: 700;
    margin-top: 6px;
  }
}

:global {
  .col-center {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    :hover {
      background-color: #fff;
      border-radius: 50px;
    }
  }

  .answer-box {
    display: flex;
    align-items: center;
    width: 50%;
  }

  .list-answer {
    margin: 20px 0;
  }
  .index-ans {
    color: #a5a072;
    font-size: 20px;
    margin-right: 10px;
    min-width: 38px;
  }

  .btn-result {
    display: flex;
    justify-content: center;
    padding: 5px;
    width: 250px;
    background-color: #fff;
    border-radius: 50px;
    margin-top: 20px;
    :hover {
      cursor: pointer;
    }
  }
}

.resultWrap {
  max-width: 695px;
  margin: 0 auto;

  .isPass {
    background-color: #ffffff;
    padding: 4px 44px;
    margin: 12px 0 18px;
    font-size: 28px;
    font-weight: 700;
    border-radius: 50px;
  }

  :global {
    .dot-result {
      color: #fff;
      height: 180px;
      width: 180px;
      border-radius: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &-good {
        background-color: #a5a072;
        border: 20px solid #e0ddc7;
      }

      &-great {
        background-color: #ed5d8e;
        border: 20px solid #ffe2d7;
      }

      &-congrats {
        background-color: #60d888;
        border: 20px solid #eafcb1;
      }
    }

    .box-title {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .correct-option {
      font-size: 80px;
      height: 80px;
      line-height: 80px;
    }

    .total-option {
      font-size: 31px;
      height: 31px;
      line-height: 31px;
    }

    .index-ans {
      color: #a5a072;
      font-size: 20px;
      margin-right: 10px;
    }

    .correct-answer {
      height: 20px;
      width: 20px;
      background-color: #f7f7f7;
      border-radius: 50%;
      border: 4px solid #ff5277;
      display: inline-block;
    }

    .wrong-answer {
      height: 20px;
      width: 20px;
      color: #717071;
      font-size: 20px;
      display: flex;
      align-items: center;
      font-weight: 700;
    }

    .col-center {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      :hover {
        background-color: #fff;
        border-radius: 50px;
      }
    }

    .box-header {
      font-size: 40px;
      color: #a5a072;
      margin: 20px 0;
    }

    .list-answer {
      margin: 20px 0;
    }
  }
}

.boxNote {
  text-align: center;

  color: #a5a072;
  background-color: #fff;
  font-size: 12px;
  border-radius: 50px;
  padding: 12px 60px;
  box-shadow: 0 0 20px 20px rgba(0, 0, 0, 0.01);

  @media (max-width: 768px) {
    padding: 12px;
  }
}

.subjectPoint {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-left: 20px;
  .textRight {
    text-align: center;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
  }
  .textLeft {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    .failed {
      color: 'red' !important;
      // font-weight: 700;
    }
  }
}

.titleSubject {
  color: #a5a072;
  margin-bottom: 8px;
  font-weight: 700;
}

.subjectName {
  font-weight: 700;
}

.modalPause {
  :global {
    .ant-modal-content {
      border-radius: 30px;
      padding: 40px;
    }
  }

  p {
    text-align: center;
    font-weight: 600;
    font-size: 18px;
  }
}

.btnActions {
  gap: 16px;
  margin: 30px 0 0;
  display: flex;
  flex-direction: row;
}

.buttonModal {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  border-radius: 30px;
  padding: 12px 16px;
  background-color: #717071;
  margin: 0 5px;
  color: white;
  flex: 1 1 0 !important;
}

.btnPause {
  cursor: pointer;
  border-radius: 30px;
  padding: 12px 16px;
  background-color: #717071;
  width: max-content;
  margin: 12px 0;
  color: white;
}

.modalClose {
  :global {
    .ant-modal-content {
      border-radius: 30px;
      padding: 40px;
    }
  }

  p {
    text-align: center;
    font-weight: 600;
    font-size: 18px;
  }
}
