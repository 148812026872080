@import '../../../src/styles/mixins';

.header {
  &.signin {
    background: #fffcf8;
  }

  @include mobile {
    box-shadow: none;
    background: #fffcf8;
    background-image: url(../../assets/images/Login/login_header_mobile.svg);
    background-repeat: no-repeat;
    background-position: center;
    // min-height: 240px;

    &.signin {
      background: #fff;
      background-image: url(../../assets/images/Login/login_header_mobile_signin.svg);
      background-repeat: no-repeat;
      background-position: center;
    }

    &.in-test {
      background: #f7f7f7;
      background-image: url(../../assets/images/Login/login_header_mobile_signin.svg);
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  .menu-icon {
    cursor: pointer;
  }

  .d-flex {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .app-logo {
    flex: 0 0 100px !important;
    max-width: 100px !important;
    min-width: 100px !important;
    width: 100px !important;

    @include xl-pc {
      flex: 0 0 8% !important;
      max-width: 8% !important;
      min-width: 8% !important;
      width: 8% !important;
    }
  }

  .activePosition {
    position: relative;
    @media only screen and (max-width: 767px) {
      position: unset !important;
    }
  }

  .app-name {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    height: 50px;
  }

  .img-logo {
    height: 50px;
  }
}
