.wrap {
}

.modalNotiExam {
  :global {
    .ant-modal-content {
      border-radius: 24px;
      padding: 50px 50px 30px;
    }

    .ant-modal-close {
      background-color: transparent !important;
    }
    p {
      margin-bottom: 10px;
      color: #727272;
      font-size: 16px;
    }
  }
}

.btn {
  margin: 30px auto 0;
  display: block;
  background-color: #707070;
  color: white !important;
  border-radius: 24px;
  padding: 8px 32px;
  height: initial;
  border: none;
}
