.sider {
  text-align: center;
  transition: 0.5s ease-in-out;
  height: 0;
  overflow: hidden;

  svg {
    cursor: pointer;
  }

  // @include mobile {
  position: fixed;
  right: 0;
  width: 100%;
  height: 100vh;
  background: #fff;
  // }

  ul {
    li {
      cursor: pointer;
      padding: 10px 20px;
      margin-bottom: 15px;
      text-align: center;

      &:hover {
        background: #fffcf8;
      }

      a {
        font-size: 1rem;
        font-weight: 500;
        color: #a5a072;
        white-space: nowrap;
      }
    }
  }

  .logout {
    display: inline-block;
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 1rem;
    font-weight: 500;
    color: #a5a072;
    margin-top: 20px;
    cursor: pointer;
    border: 2px solid #a5a072;
    border-radius: 25px;

    &:hover {
      border: none;
      background: #a5a072;
      color: #fff;
    }
  }
}
