@import './mixins';
@import './variables';
@import './custom-antd';
@tailwind base;
@tailwind components;
@tailwind utilities;
html,
body {
  padding: 0;
  margin: 0;
  min-height: 100vh;
}

ul,
li {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}

.custom-sider-ant {
  flex: 0 0 100px !important;
  max-width: 100px !important;
  min-width: 100px !important;
  width: 100px !important;

  @include xl-pc {
    flex: 0 0 8% !important;
    max-width: 8% !important;
    min-width: 8% !important;
    width: 8% !important;
  }
}

button[disabled] {
  cursor: not-allowed;
}

.boxShadowCustom {
  box-shadow: rgb(235, 167, 212) 5px 5px 6px 0px, rgba(255, 255, 255, 0.5) 0px 0px 0px 0px;
}

// .boxShadowCustom::before {
//   @include mobile {
//     content: '';
//     position: absolute;
//     top: 0;
//     right: 0;
//     bottom: 0;
//     left: 0;
//     z-index: -1;

//     border-radius: inherit;
//     background: linear-gradient(to right, red, orange);
//   }
// }
